import 'react-tooltip/dist/react-tooltip.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'animate.css/animate.css';
import './src/scss/global.scss'

export const onRouteUpdate = ({ location }) => {
    window.scrollTo(0, 0);
  };

